.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--primaryColor);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 60vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.homePageContainer {
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: center;
}

.homePageContentContainer {
  display: flex;
  flex-direction: column;
  height: 25%;
  justify-content: space-between;
}

.homePageVisitOurText {
  display: flex;
  justify-content: center;
}

.homePageWebsiteHyperLink {
  border-bottom: solid 1px white;
  cursor: pointer;
}

:root {
  --primaryColor: #3f0e40;
}

