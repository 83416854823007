.adminLoginComponentContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.adminLoginDetailsContainer {
  width: 70%;
  height: 40%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.adminLoginInputContainer {
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.adminLoginButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.adminLoginButton {
  color: white !important;
  background-color: var(--primaryColor) !important;
  font-size: x-large;
  border-radius: 5px;
  z-index: 1;
}

.adminLoginButton:hover {
  transform: scale(1.2) !important;
  color: var(--primaryColor) !important;
  background-color: white !important;
  border: solid 1px var(--primaryColor);
}

.adminLoginDisabledButton {
  color: white;
  background-color: lightgray;
}

.adminLoginDisabledButtonContainer {
  border: solid 1px black;
  border-radius: 5px;
  cursor: not-allowed;
}
