.meetingPasswordView {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: aliceblue;
}

.meetingPasswordViewContainer {
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.meetingPasswordViewHeaderText {
  color: var(--primaryColor);
  font-size: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.meetingPasswodInputContainer {
  width: 70%;
  color: var(--primaryColor);
}

.meetingPasswordViewPasswordContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.meetingPasswordVerfiyButton {
  margin-top: 5px;
}

.disabledVerifyMeetingPasswordButton {
  color: white !important;
  background-color: lightgray !important;
  border-radius: 5px !important;
  border-color: solid 1px black !important;
  cursor: not-allowed !important;
}

.verifyMeetingPasswordButton {
  color: var(--primaryColor) !important;
  background-color: white !important;
  border-radius: 5px !important;
  border-color: solid 1px black !important;
}

.verifyMeetingPasswordButton:hover {
  transform: scale(1.2) !important;
  background-color: var(--primaryColor) !important;
  color: white !important;
  border-color: solid 1px black;
}
