.adminViewAllMeetingsTopNavBarContainer {
  height: 10vh;
}

.adminViewAllMeetings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
}

.adminViewAllMeetingDetails {
  display: flex;
  justify-content: space-between;
  width: 80%;
  color: var(--primaryColor);
  border: solid 1px var(--primaryColor);
  margin-top: 2vh;
  font-family: sans-serif;
  font-size: large;
  /*overflow: scroll;*/
  height: 8vh;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.adminViewAllMeetingsMeetingId {
  display: flex;
  width: 50%;
  margin-left: 1vw;
}

.adminViewAllMeetingsMeetingTitle {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  margin-right: 1vw;
  height: 8vh;
  /*overflow-x: scroll;*/
  align-items: center;
}

.adminViewAllMeetingsLoadMoreMeetingsButton {
  display: flex;
  justify-content: center;
  margin-top: 3vh;
}

.adminLoadMoreMeetingsButton {
  color: white !important;
  background-color: var(--primaryColor) !important;
}

.adminLoadMoreMeetingsButton:hover {
  color: var(--primaryColor) !important;
  background-color: white !important;
  border: solid 1px #323232 !important;
  transform: scale(1.2);
}

*, *::before, *::after {
  box-sizing: inherit;
  overflow: visible;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}