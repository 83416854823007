.adminCreateMeetingComponentContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.adminCreateMeetingTopNavBarContainer {
  height: 10vh;
}

.adminCreateMeetingTitleField {
  height: 15vh;
  margin-top: 2vh;
}

.adminCreateMeetingTitleField .MuiInputLabel-outlined {
  margin-top: 5px !important;
}

.adminCreateMeetingStartTimeSelector {
  height: 12vh;
}

.adminCreateMeetingEnableModeratorControlContainer {
  height: 15vh;
  margin-top: 3vh;
}

.adminCreateMeetingEnableModeratorControlContainer .MuiInputLabel-outlined {
  margin-top: 5px !important;
}

.adminCreateMeetingInviteTypeContainer {
  height: 20vh;
  margin-top: 3vh;
}

.adminCreateMeetingInviteTypeContainer .MuiInputLabel-outlined {
  margin-top: 5px !important;
}

.adminCreateMeetingEnableModeratorCheckbox {
  display: flex;
  align-items: center;
  justify-content: left;
}

.adminCreateMeetingEnableModeratorCheckbox .PrivateSwitchBase-root-5 {
  padding: 9px 9px 9px 0px;
}

.adminCreateMeetingDetailsContainer {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  /* overflow-y: scroll; */
}

.adminCreateMeetingInputContainer {
  width: 100%;
  /* height: 75%; */
  height: 710px;
  overflow-y: auto;
  /* display: flex;
  justify-content: space-evenly;
  flex-direction: column; */
}

.adminCreateMeetingTitleContainer {
  display: flex;
  justify-content: center;
  font-size: x-large;
  color: var(--primaryColor);
  font-family: sans-serif;
  height: 5vh;
}

.adminCreateMeetingPasswordContainer {
  height: 33vh;
  display: flex;
  flex-direction: column;
}

.adminCreateMeetingEnablePasswordContainer {
  display: flex;
  align-items: center;
  justify-content: left;
}

.adminCreateMeetingEnablePasswordContainer .PrivateSwitchBase-root-5 {
  padding: 9px 9px 9px 0px;
}

.adminCreateMeetingPasswordInputField {
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.adminCreateMeetingPasswordInputField .MuiInputLabel-outlined {
  margin-top: 5px !important;
}

.adminCreateMeetingDisabledMeetingPasswordInputField {
  cursor: not-allowed;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.adminCreateMeetingDisabledMeetingPasswordInputField .MuiInputLabel-outlined {
  margin-top: 5px !important;
}

.adminCreateMeetingButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5vh;
  margin-top: 3vh;
}

.adminCreateMeetingButton {
  color: white !important;
  background-color: var(--primaryColor) !important;
  font-size: x-large;
  border-radius: 5px;
  z-index: 1;
}

.adminCreateMeetingButton:hover {
  transform: scale(1.2) !important;
  color: var(--primaryColor) !important;
  background-color: white !important;
  border: solid 1px var(--primaryColor);
}

.adminCreateMeetingDisabledButton {
  color: white;
  background-color: lightgray;
}

.adminCreateMeetingDisabledButtonContainer {
  border: solid 1px black;
  border-radius: 5px;
  cursor: not-allowed;
}

.adminCreateMeetingButtonDisable {
  color: white !important;
  background-color: lightgray;
}

.meetingTitlePreview {
  display: flex;
  justify-content: center;
  color: var(--primaryColor);
  font-size: x-large;
  font-family: sans-serif;
}

.meetingStartTimePreview {
  height: 5vh;
  color: var(--primaryColor);
  font-size: large;
}

.meetingEndTimePreview {
  height: 5vh;
  color: var(--primaryColor);
  font-size: large;
}

.meetingPasswordPreview {
  height: 5vh;
  color: var(--primaryColor);
  font-size: large;
}

/* @media only screen and (max-height: 880px) and (max-width: 1296px) {
  .adminCreateMeetingInputContainer {
    height: 600px;
  }
  .adminCreateMeetingInviteTypeContainer {
    height: 25vh;
    margin-top: 4vh;
  }
  .adminCreateMeetingPasswordInputField {
    height: 82%;
  }
} */

@media only screen and (max-height: 864px) and (max-width: 1536px) {
  .adminCreateMeetingInputContainer {
    height: 550px;
  }
  .adminCreateMeetingInviteTypeContainer {
    height: 25vh;
    margin-top: 4vh;
  }
  .adminCreateMeetingPasswordInputField {
    height: 82%;
  }
  .adminCreateMeetingEnableModeratorControlContainer {
    height: 20vh;
  }
}

@media only screen and (max-height: 720px) and (max-width: 1280px) {
  .adminCreateMeetingInputContainer {
    height: 450px;
  }
  .adminCreateMeetingInviteTypeContainer {
    height: 28vh;
    margin-top: 4vh;
  }
  .adminCreateMeetingPasswordInputField {
    height: 85%;
  }
  .adminCreateMeetingEnableModeratorControlContainer {
    height: 24vh;
  }
} 