.adminActionsContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.adminActions {
  display: flex;
  flex-direction: column;
  height: 30vh;
  justify-content: space-between;
}

.adminActionsCreateMeetingContainer {
  display: flex;
  height: 7vh;
  width: 100%;
}

.adminActionsViewMeetingsContainer {
  display: flex;
}

.adminActionsCreateMeetingButton {
  background-color: var(--primaryColor) !important;
  color: white !important;
}

.adminActionsCreateMeetingButton:hover {
  transform: scale(1.2);
  border: solid 1px var(--primaryColor) !important;
  background-color: white !important;
  color: var(--primaryColor) !important; 
}

.adminActionsViewMeetingsButton {
  width: 100% !important;
  background-color: var(--primaryColor) !important;
  color: white !important;
}

.adminActionsViewMeetingsButton:hover {
  transform: scale(1.2);
  border: solid 1px var(--primaryColor) !important;
  background-color: white !important;
  color: var(--primaryColor) !important; 
}
